export class Prodotto {
	constructor() {
		this.itemID = null;
		this.nomeProdotto = null;
		this.descrizione = null;
		this.tipologiaID = null;
		this.tipologiaProdotto = null;
		this.fornitoreID = null;
		this.fornitoreRagioneSociale = null;
		this.vendibileSingolarmente = null;
		this.pubblico = null;
		this.cancellato = null;
		this.contraenteID = null;
		this.contrattoNumero = null;
		this.itemGUID = null;
		this.fileInvioOnlineID = null;
		this.codiceTariffa = null;
		this.valoreLogin = null;
		this.messaggioWarning = null;
		this.mostraNazionalita = null;
		this.mostraNelWidget = null;
		this.deleted = null;
		this.controlloIddEscluso = null;
	}

	itemID: number;
	nomeProdotto: string;
	descrizione: string;
	tipologiaID: number | null;
	tipologiaProdotto: string;
	fornitoreID: number | null;
	fornitoreRagioneSociale: string;
	vendibileSingolarmente: boolean | null;
	pubblico: boolean | null;
	cancellato: boolean | null;
	contraenteID: number | null;
	contrattoNumero: string;
	itemGUID: string;
	fileInvioOnlineID: number;
	codiceTariffa: string;
	valoreLogin: string;
	messaggioWarning: string;
	mostraNazionalita: boolean;
	mostraNelWidget: boolean;
	controlloIddEscluso: boolean;
	deleted: boolean | null;
}