// import Vue from 'vue'
import { Component, Watch } from "vue-property-decorator";
import api from "@/services/api";
import { TextValueItem } from "../../../models/TextValueItem";
import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Prodotto } from "@/models/prodotti/prodotto";
import appImage from "@/components/appImage.vue";
import permessi from "@/config/permessi";
import { EseguiComandoRequest } from "@/models/comandi/eseguiComandoRequest";
import { operationResult } from "@/models/operationResult";
import { VNode } from "vue";

const _ENDPONT = api.endpoint.POLIZZE.POLIZZE;

@Component({})
export default class polizePage extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Prodotti.POLIZZE];
	}
	editItem: Prodotto = new Prodotto();
	showEditModal: boolean = false;
	currentItem: any = {};
	showOnlyActive: boolean = false;

	tipologieProdotti: TextValueItem[] = [];
	fornitori: TextValueItem[] = [];
	contraenti: TextValueItem[] = [];
	fileInvio: TextValueItem[] = [];

	get columns(): Array<any> {
		return [
			{
				field: "itemID",
				title: "ID",
				filterType: "numeric-int",
				width: 80,
				filterable: true,
				hidden: false,
				headerCell: "myHeaderCellTemplate",
				editHide: true
			},
			{ field: "nomeProdotto", title: this.$i18n.t("generico.polizza").toString(), headerCell: "myHeaderCellTemplate", export: true },
			{ field: "descrizione", title: this.$i18n.t("generico.descrizione").toString(), width: 150, hidden: true, headerCell: "myHeaderCellTemplate" },
			{
				field: "tipologiaID",
				title: this.$i18n.t("generico.tipologia").toString(),
				width: 150,
				values: this.tipologieProdotti,
				sortBy: "tipologiaProdotto",
				filterType: "select",
				itemsForFilter: this.tipologieProdotti,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				editType: "select",
				editSource: this.tipologieProdotti,
				export: true
			},
			{
				field: "fornitoreID",
				title: this.$i18n.t("generico.fornitore").toString(),
				width: 150,
				values: this.fornitori,
				sortBy: "fornitoreRagioneSociale",
				filterType: "select",
				groupable: true,
				itemsForFilter: this.fornitori,
				headerCell: "myHeaderCellTemplate",
				editType: "select",
				editSource: this.fornitori,
				export: true
			},
			{ field: "vendibileSingolarmente", title: this.$i18n.t("generico.vendibileSingolarmente").toString(), width: 100, hidden: true, headerCell: "myHeaderCellTemplate", type: "boolean" },
			
			{
				field: "contraenteID",
				title: this.$i18n.t("generico.contraente").toString(),
				width: 150,
				values: this.contraenti,
				sortBy: "contraente",
				filterType: "select",
				itemsForFilter: this.contraenti,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				editType: "select",
				editSource: this.contraenti,
				export: true
			},
			//{ field: "contraenteID", title: this.$i18n.t("generico.contraente").toString(), hidden: false, editType: "select", editSource: this.contraenti, headerCell: "myHeaderCellTemplate", width: 150 },
			{ field: "contrattoNumero", title: this.$i18n.t("generico.contratto").toString(), hidden: false, headerCell: "myHeaderCellTemplate", export: true, width: 150 },
			{
				field: "fileInvioOnlineID",
				title: this.$i18n.t("generico.fileInvio").toString(),
				width: 150,
				groupable: true,
				hidden: true,
				headerCell: "myHeaderCellTemplate",
				editType: "select",
				editSource: this.fileInvio
			},
			{ field: "valoreLogin", title: this.$i18n.t("generico.codiceLogin").toString(), width: 90, hidden: true, headerCell: "myHeaderCellTemplate" },
			{ field: "sconto2_Valore", title: this.$i18n.t("generico.valore2").toString(), width: 70, headerCell: "myHeaderCellTemplate", editType: "numeric", editHide: true, hidden: true },
			{ field: "messaggioWarning", title: this.$i18n.t("generico.messaggioWarning").toString(), width: 120, hidden: true, headerCell: "myHeaderCellTemplate" },
			{
				field: "cancellato",
				title: this.$i18n.t("generico.cancellato").toString(),
				width: 120,
				type: "boolean",
				filterType: "boolean",
				headerCell: "myHeaderCellTemplate",
				export: true,
				disabled: true
			},
			{
				field: "mostraNazionalita",
				title: this.$i18n.t("generico.mostraNazionalita").toString(),
				width: 120,
				type: "boolean",
				filterType: "boolean",
				headerCell: "myHeaderCellTemplate",
				export: true,
				disabled: true
			},
			{
				field: "controlloIddEscluso",
				title: this.$i18n.t("generico.controlloIddEscluso").toString(),
				type: "boolean",
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				export: true,
				hidden: true,
				editType: "boolean"
			},
			{ field: "_actions", title: " ", width: 100 + (this.canDelete ? 50 : 0), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	sort: any = [{ field: "nomeProdotto", dir: "asc" }];

	created() {
		var self = this;
		var p = api.getDatiDropDown(api.endpoint.DROPDOWN.TIPOLOGIE_PRODOTTI).then(res => (self.tipologieProdotti = res));
		var p2 = api.getDatiDropDown(api.endpoint.DROPDOWN.FORNITORI).then(res => (self.fornitori = res));
		var p3 = api.getDatiDropDown(api.endpoint.DROPDOWN.CONTRAENTI).then(res => (self.contraenti = res));
		var p4 = api.getDatiDropDown(api.endpoint.DROPDOWN.FILEINVIO).then(res => (self.fileInvio = res));

		Promise.all([p, p2, p3, p4])
			.then(responses => {
				if (!self.showOnlyActive) {
					self.filter.filters.push({
						field: "cancellato",
						operator: "eq",
						value: "!!false!!"
					});
				}
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	mounted() {}

	@Watch("showOnlyActive")
	onShowOnlyActiveChange(newValue: boolean) {
		const field = "cancellato";
		if (!newValue) {
			this.filter.filters.push({
				field: field,
				operator: "eq",
				value: "!!false!!"
			});

			this.setDefaultPageSize();
			this.getData();
		} else {
			var findFilterIndex = this.filter.filters.findIndex(function(item) {
				return item.field === field;
			});

			this.filter.filters.splice(findFilterIndex, 1);

			this.setDefaultPageSize();
			this.getData();
		}
	}

	addNewRow() {
		let item = new Prodotto();

		this.onEdit(item);
	}

	exportExcel() {
		this.exportGridExcel(_ENDPONT);
	}

	getData() {
		var self = this;
		this.getGridData(_ENDPONT);
	}
	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.nomeProdotto}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.polizza")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}
	onEdit(item: any) {
		var self = this;

		self.editItem = Object.assign(new Prodotto(), item);
		self.editSubTitle = item.descrizione;
		self.editTitle = item.nomeProdotto;
		self.showEditModal = true;
	}
	editTitle: string = null;
	editSubTitle: string = null;
	get isNewItem(): boolean {
		var self = this;
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
	onSaveEdit() {
		var self = this;
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();

		if (self.isNewItem) {
			self.editItem.cancellato = false;
		}

		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.polizza") }).toString();
		self.setGridRow<Prodotto>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false;
		});
	}

	eseguiSincronizzaPolizza(itemId: number) {
		var self = this;
		let cmd = "CreaSchedaPolizza";
		let data: EseguiComandoRequest = {
			comando: cmd,
			elenco: [],
			parametri: [itemId]
		};
		api.eseguiComando(data)
			.then(res => {
				self.$bvToast.toast(this.$i18n.t("generico.duplicazioneOk").toString(), {
					variant: "success",
					title: this.$i18n.t("generico.azioneeseguita").toString(),
					solid: true
				});

				self.getData();
			})
			.catch(err => {
				self.getData();
				// self.$bvToast.toast(this.$i18n.t("generico.erroreGenerico").toString(), {
				// 	variant: "error",
				// 	title: this.$i18n.t("generico.errore").toString(),
				// 	solid: true
				// });
			});
	}

	duplicaPolizza(item: Prodotto) {
		var self = this;

		var newitem = new Prodotto();

		newitem.itemID = item.itemID;
		newitem.nomeProdotto = item.nomeProdotto + " - " + this.$i18n.t("generico.copia").toString();
		newitem.descrizione = "";

		var msg = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.nomeProdotto}</h5>${self.$i18n.t("messaggio.confermaDuplicazione")}` } });
		self.$bvModal
			.msgBoxConfirm([msg], {
				title: this.$i18n.t("generico.duplica").toString() + " " + this.$i18n.t("generico.polizza").toString(),
				okVariant: "warning",
				okTitle: self.$i18n
					.t("btn.si")
					.toString()
					.toUpperCase(),
				cancelTitle: self.$i18n
					.t("btn.no")
					.toString()
					.toUpperCase(),
				cancelVariant: "outline-theme",
				footerClass: "",
				headerBgVariant: "warning"
				// centered: true
			})
			.then(value => {
				if (value) {
					api.chiamataPost(`${_ENDPONT}/clone`, newitem)
						.then(res => {
							//self.getData();
							if (res && res.data) {
								self.eseguiSincronizzaPolizza(res.data as number);
							}
						})
						.catch(err => console.log(err));
				}
			})
			.catch(err => {
				// An error occurred
			});
	}
}
